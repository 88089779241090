// --------------------------------------------------
// Theme
// --------------------------------------------------

.menu{
  a{
	text-transform: uppercase;
	font-weight:bold;	
  // font-size: 0.8em;
    &.active{
      border-bottom:3px solid $lightblue; 
    }
  }
  &.hover{
    a:hover,a:active{
      border-bottom:3px solid $lightblue; 
    }
  }
}

.progress .meter {
	-moz-transition:width 0.7s ease;
	-webkit-transition:width 0.7s ease;
	-transition:width 0.7s ease;
}
.progress.large{
	height:2rem;
}
/* Stacked progress meter fix */
.progress-meter.stacked{
	display:inline-block;
	float:left;
	.progress-meter-text{
		font-size:1rem;
	}
}
article,.articles{
  h1,h2,h3,h4,h5{
    font-weight:bold;
    @extend .bkc;
    letter-spacing:-1px;
  }
}

form{
 &.large{
  .input-group-field{
    height: 4rem;
    box-shadow:none;
    font-weight:bold;
    // text-align: right;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }
  .input-group-label{
    background:none;
  }
 }
}

.pagination{
  li{
    display:inline-block;
    font-weight:bold;
    text-align:center;
    border-radius:3px;
    a {
      padding:0.5em 1em;
      display:inline-block;
      border-radius:3px;
    }
    
    &.unavailable{
      display:none;
    }
    &.current{
      background:$darkblue;
      a{color:$white;}
    }
  }
}

.cyclelist {
  // width:200px;
  position:relative;
  overflow:hidden;
  height:60px;
  li{
    opacity:0;
    position:absolute;
  }
}
