// --------------------------------------------------
// State
// --------------------------------------------------

//Small-only
@media screen and (max-width: 39.9375em) {
	.cbg{
	  background:#fafafa url('../img/capsule.jpg') no-repeat;background-position:-120% 150%;
	}
	.fsuitbg,.helmetbg{
		background:none;
	}
	.rsuitbg{
	  background:#fafafa url('../img/rsuit.jpg') no-repeat; background-position: -248% 50%;
	}
	.ssbg{
	  background:#fafafa url('../img/spaceshuttle.jpg') no-repeat; background-position: 160% 100%;
	}
	.robotwbg{
	  background:#fafafa url('../img/robotw.jpg') no-repeat;background-position: -90% 80%;
	}
	.robotbg{
	  background:#fafafa url('../img/robot2.jpg') no-repeat; background-position: -240% 0%;
	}
}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {} 